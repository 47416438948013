* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #374151;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.2rem;
    margin-bottom: 2px;
  }
}

footer {
  /* Place the footer at the bottom of each page */
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  padding-top: 10px;

  /* Any other appropriate styling */
  color: #374151;
}

@media print {
  @page {
    size: 216mm 330mm;
    height: 100%;
    margin: 0 !important;
    margin-bottom: 1cm !important;
    padding: 0 !important;
    overflow: hidden;
  }

  @page: right {
    @bottom-right {
      margin: 10pt 0 30pt 0;
      border-top: 0.25pt solid #666;
      content: counter(page);
      font-size: 9pt;
    }
  }

  .page_number:before {
    content: 'Page ' counter(page) ' of ' counter(pages);
  }
}
